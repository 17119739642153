import React from "react"
import ChirurgoLoginContainer from "../components/ChirurgoLoginContainer/ChirurgoLoginContainer"

import LayoutChirurghi from "../components/layoutChirurghi"
import SEO from "../components/seo"

const ChirurgoLogin = ({ location }) => {
  return (
    <LayoutChirurghi pathname={location.pathname}>
      <SEO title="Login chirurghi" />
      <ChirurgoLoginContainer />
    </LayoutChirurghi>
  )
}

export default ChirurgoLogin
