import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link as GatsbyLink } from "gatsby"
import React, { useState } from "react"
import styled from "styled-components"
import { device } from "../../constants/breakpoints"
import { colors } from "../../constants/Colors"
import CustomButton from "../Atoms/Button"
import CheckBox from "../CheckBox/CheckBox"
import { NoDesktop } from "../ChirurghiSearch/styles/ChirurghiSearch.styled"
import ContactForm from "../Form/Form"

const Container = styled.div`
  padding-top: 60px;
  padding-bottom: 160px;
  border-top: 20px solid black;
  border-top-color: ${({ theme }) => theme.ocean};
  background: #fbfbfb;
  text-align: "center";
  @media ${device.tablet} {
    padding-top: 20px;
  }
`

const Wrapper = styled.div`
  max-width: 500px;
  margin: auto;
  text-align: center;
`

const Title = styled.p`
  font-size: 24px;
  color: ${({ theme }) => theme.midblue};
  text-align: center;
`

const Link = styled(GatsbyLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.lightblue};
`
export default function ChirurgoLoginContainer() {
  const [accept, setAccept] = useState(false)
  return (
    <Container>
      <Wrapper>
        <Title>Accesso Dottore</Title>
        <ContactForm
          style={{
            margin: "auto",
            paddingTop: 20,
            paddingBottom: 20,
            borderRadius: 6,
          }}
        >
          <ContactForm.Wrapper style={{ paddingLeft: 40, paddingRight: 40 }}>
            <ContactForm.Input title={"noresize"} placeholder={"Email"} />
            <ContactForm.Input title={"noresize"} placeholder={"Password"} />
            <CheckBox
              style={{ marginLeft: 10 }}
              onClick={() => setAccept(!accept)}
            >
              <span style={{ float: "left" }}>Resta connesso</span>
              <CheckBox.Input
                type="checkbox"
                checked={accept ? "checked" : false}
              />
              <CheckBox.CheckMark></CheckBox.CheckMark>
            </CheckBox>
          </ContactForm.Wrapper>
        </ContactForm>
        <CustomButton
          color={colors.BLUE}
          style={{
            margin: "auto",
            marginTop: 20,
            paddingLeft: 25,
            paddingRight: 25,
          }}
        >
          ACCEDI
        </CustomButton>
        <br />
        <div style={{ height: 20 }} />

        <Link
          to={"/"}
          style={{
            color: colors.BLUE,
            textTransform: "uppercase",
            fontSize: 11,
          }}
        >
          Hai dimenticato la password?
        </Link>
        <div style={{ height: 10 }} />
        <NoDesktop>
          <Link to={"/"}>
            <FontAwesomeIcon
              style={{ fontSize: 11, fontWeight: 100 }}
              icon={faArrowLeft}
            />
            <span style={{ fontSize: 14, fontWeight: 400, marginLeft: 10 }}>
              Torna a chirurghi estetici
            </span>
          </Link>
        </NoDesktop>
      </Wrapper>
    </Container>
  )
}
